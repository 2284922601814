import { isEmpty } from 'lodash'
import { defineStore } from 'pinia'
import { CACHE, getCache, setCache } from 'shared'
import { COMMON_PAGINATION_DF } from 'shared/utils/constants'

import { CommonService } from '@/services/commons'
import { getNoticeParameter, NationCodeParams, useCommonState } from '@/types'

import { useAuthStore } from './auth'

const commonService = new CommonService()

export const useCommonStore = defineStore('commonStore', {
  state: (): useCommonState => ({
    vesselCodes: getCache(CACHE.VSL_CD) ?? [],
    suppliers: getCache(CACHE.SUPPLIER) ?? [],
    approvalStatus: [],
    portCodes: getCache(CACHE.PORT_CODES) ?? [],
    documentNoRes: null,
    commonCodes: getCache(CACHE.COMMON_CODES) ?? {},
    files: [],
    recordTypes: getCache(CACHE.RECORD_TYPES) ?? [],
    unreadCounts: {},
    groups: [],
    users: getCache(CACHE.PIC_USER) ?? [],
    portCodesV2: { data: [], page: COMMON_PAGINATION_DF },
    nationCodes: getCache(CACHE.NATION_CODES) ?? [],
    suppliersV2: { data: [], page: COMMON_PAGINATION_DF },
    noticeRes: { data: [], page: COMMON_PAGINATION_DF },
  }),
  actions: {
    getStatusByCode(key: string, code: string) {
      if (!this.commonCodes[key]) return ''
      const status = this.commonCodes[key].find((item) => item.vmsCommonCode === code)
      return status ? status.vmsCommonName : ''
    },
    initCommonData() {
      this.getCommonCode()
      this.getRecordTypes()
      // this.getUsers()
      this.getNationCodes()
    },
    async getVesselCodes() {
      if (isEmpty(this.vesselCodes)) {
        const response = await commonService.getVesselCodeList()
        this.vesselCodes = response.data
        setCache(CACHE.VSL_CD, response.data)
      }
    },
    async getCommonCode() {
      if (!isEmpty(this.commonCodes)) {
        return
      }
      const response = await commonService.getCommonCodes([])
      let rawCommonCodes = {}
      Object.keys(response.data).forEach((key) => {
        const commonCode = response.data[key].map((item) => ({
          vmsCommonName: item.vmsCommonName,
          vmsCommonCode: item.vmsCommonCode,
        }))
        rawCommonCodes = { ...rawCommonCodes, [key]: commonCode }
      })
      this.commonCodes = rawCommonCodes
      setCache(CACHE.COMMON_CODES, rawCommonCodes)
    },
    async getPortList(params: NationCodeParams) {
      const response = await commonService.getPortList(params)
      setCache(CACHE.PORT_CODES, response.data)
      this.portCodes = response.data
      return response.data
    },
    async getCommonCodes(groupCodes, query) {
      const response = await commonService.getCommonCodes(groupCodes, query)
      return response.data
    },
    async getFiles(vmsFileId: number) {
      const response = await commonService.getFiles(vmsFileId)
      this.files = response.data
      return response.data
    },
    async getRecordTypes() {
      if (!isEmpty(this.recordTypes)) {
        return
      }
      const response = await commonService.getRecordTypes()
      setCache(CACHE.RECORD_TYPES, response.data)
      this.recordTypes = response.data
    },
    async getGroups() {
      const response = await commonService.getGroups()
      this.groups = response.data
      return response.data
    },

    async getUsers() {
      if (!isEmpty(this.users)) {
        return
      }
      const params = {
        emailGroupCode: useAuthStore().userInfo.emailGroupCode,
        size: 1000,
        page: 0,
      }
      const response = await commonService.getPicUsers(params)
      this.users = response.data
      setCache(CACHE.PIC_USER, response.data)
    },
    async getReport() {
      const response = await commonService.getReport()
      return response
    },
    async getFollowUpDetail(taskIdSequence: number, emailIdSequence: number) {
      const response = await commonService.getTaskManageDetail(taskIdSequence, emailIdSequence)
      return response.data
    },
    async getPortListV2(params: NationCodeParams) {
      const response = await commonService.getPortListV2(params)
      this.portCodesV2 = response
      return response
    },
    async getNationCodes() {
      if (isEmpty(this.nationCodes)) {
        const response = await commonService.getNationCodes()
        this.nationCodes = response.data
        return response.data
      }
    },
    async getSupplierListV2(params: NationCodeParams) {
      const response = await commonService.getSupplierListV2(params)
      this.suppliersV2 = response
      return response
    },
    async getNotices(params: getNoticeParameter) {
      const response = await commonService.getNotices(params)
      this.noticeRes = response
    },
  },
})
